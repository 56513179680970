<template>
    <div>
        <div class="accordion custom-accordion" id="custom-accordion-one">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="form-group row" v-if="$parent.truck_no">
                        <label class="col-md-3 col-form-label">Truck No</label>
                        <div class="col-md-9">
                            <input :value="$parent.truck_no" class="form-control" readonly>
                        </div>
                    </div>
                    <div class="form-group row" v-if="$parent.gross_weight && $parent.clicked_step_index === 4">
                        <label class="col-md-3 col-form-label">Gross Weight (kg)</label>
                        <div class="col-md-9">
                            <input :value="$parent.gross_weight" class="form-control" readonly>
                        </div>
                    </div>
                    <div class="form-group row" v-if="$parent.clicked_step_index === 4">
                        <label class="col-md-3 col-form-label">{{tare_weight.name}}</label>
                        <div class="col-md-9">
                            <input :readonly="status === 'Attested'" @input="changeNetWeight()" class="form-control" id="tare_weight" name="tare_weight" type="number" v-model="tare_weight.value">
                        </div>
                    </div>
                    <div class="form-group row" v-if="$parent.clicked_step_index === 4">
                        <label class="col-md-3 col-form-label">{{net_weight.name}}</label>
                        <div class="col-md-9">
                            <input :readonly="status === 'Attested'" class="form-control" id="net_weight" name="net_weight" type="number" v-model="net_weight.value">
                        </div>
                    </div>
                    <div class="form-group row" v-if="$parent.clicked_step_index === 4">
                        <label class="col-md-3 col-form-label">{{mr_no.name}}</label>
                        <div class="col-md-9">
                            <input :readonly="status === 'Attested'" class="form-control" id="mr_no" name="mr_no" type="text" v-model="mr_no.value">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-0" v-bind:key="index" v-for="(quality, index) in qualities">
                <div :id="'headingFour'+index" class="card-header">
                    <h5 class="m-0">
                        <a :aria-controls="'collapseFour'+index"
                           aria-expanded="false" class="custom-accordion-title d-block py-1"
                           data-toggle="collapse" v-bind:href="'#collapseFour'+index">
                            Reading #{{index+1}}: Quality as per PO <i
                                class="mdi mdi-chevron-down accordion-arrow"></i>
                        </a>
                    </h5>
                </div>

                <div :aria-labelledby="'headingFour'+index" :class="{'show': index === 0}"
                     :id="'collapseFour'+index"
                     class="collapse"
                     data-parent="#custom-accordion-one">
                    <div class="card-body">
                        <div class="form-group row mb-3" v-bind:key="index1" v-for="(a, index1) in q_attrs[index]">
                            <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{a.name}}</label>
                            <div class="col-md-9" v-if="a.name===`Moisture(${index+1})`">
                                <div class="input-group">
                                    <input :id="index+'attribute_value1'+index1" :name="index+'attribute_value1'+index1" :readonly="a.readonly || status === 'Attested'"
                                           @change="checkBenchmark(a.fact.value)" class="form-control"
                                           type="text" v-model="a.fact.value">
                                    <div class="input-group-append">
                                        <span @click="getReadings(a.name, a)" class="input-group-text bg-info border-info text-white" data-target="#bs-example-modal-lg" data-toggle="modal"
                                              style="cursor: pointer"><i class="uil uil-calcualtor"></i></span>
                                    </div>
                                </div>
                                <span v-if="benchMarkFlag" class="mg-l-5 tx-13 text-warning">The moisture benchmark for the area {{mukam_area | capitalize}} is <span
                                        class="font-weight-bold">{{moisture_limit}}</span></span>
                            </div>
                            <div class="col-md-9" v-else>
                                <input :class="{'is-invalid': $parent.submitted && errors.has(index+'attribute_value1'+index1)}" :data-vv-as="a.name" :id="index+'attribute_value1'+index1"
                                       :name="index+'attribute_value1'+index1" :readonly="a.readonly || status === 'Attested'" class="form-control" type="text"
                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <input :class="{'is-invalid': $parent.submitted && errors.has(index+'attribute_value1'+index1)}" :data-vv-as="a.name"
                                       :id="index+'attribute_value1'+index1" :name="index+'attribute_value1'+index1"
                                       :readonly="a.readonly || status === 'Attested'" @input="change(a.name, a.fact.value, q_attrs[index], index)" class="form-control" type="number"
                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <select :class="{'is-invalid': $parent.submitted && errors.has(index+'attribute_value1'+index1)}" :data-vv-as="a.name"
                                        :disabled="a.readonly || status === 'Attested'" :id="index+'attribute_value1'+index1"
                                        :name="index+'attribute_value1'+index1" @change="change(a.name, a.fact.value, q_attrs[index], index)" class="form-control custom-select"
                                        v-if="a.values && a.values.length >0"
                                        v-model="a.fact.value" v-validate="{ required: a.required }">
                                    <option :value="null" selected>Select {{a.name}}</option>
                                    <option :key="val" :value="val" v-for="val in a.values">
                                        {{val}}
                                    </option>
                                </select>
                                <input :class="{'is-invalid': $parent.submitted && errors.has(index+'attribute_value1'+index1)}" :data-vv-as="a.name" :id="index+'attribute_value1'+index1"
                                       :name="index+'attribute_value1'+index1" :readonly="a.readonly || status === 'Attested'" class="form-control" type="date"
                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value"
                                       v-validate="{ required: a.required }">
                                <input :class="{'is-invalid': $parent.submitted && errors.has(index+'attribute_value1'+index1)}" :data-vv-as="a.name" :id="index+'attribute_value1'+index1"
                                       :name="index+'attribute_value1'+index1" :readonly="a.readonly || status === 'Attested'" class="form-control" type="number"
                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Integer'|| a.fact.type === 'U128')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <div class="invalid-feedback" v-if="$parent.submitted && errors.has(index+'attribute_value1'+index1)">
                                    {{errors.first(index+'attribute_value1'+index1) }}
                                </div>
                                <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input :class="{'is-invalid': $parent.submitted && errors.has('customRadio3'+index1)}" :id="'customRadio3'+index1"
                                               :readonly="a.readonly || status === 'Attested'"
                                               class="custom-control-input"
                                               name="customRadio1" type="radio" v-validate="{ required: a.required }" value="true">
                                        <div class="invalid-feedback" v-if="$parent.submitted && errors.has('customRadio3'+index1)">
                                            {{
                                            errors.first('customRadio3'+index1) }}
                                        </div>
                                        <label :for="'customRadio3'+index1" class="custom-control-label">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input :class="{'is-invalid': $parent.submitted && errors.has('customRadio4'+index1)}" :id="'customRadio4'+index1"
                                               :readonly="a.readonly || status === 'Attested'"
                                               class="custom-control-input"
                                               name="customRadio1" type="radio" v-validate="{ required: a.required }" value="false">
                                        <div class="invalid-feedback" v-if="$parent.submitted && errors.has('customRadio4'+index1)">
                                            {{
                                            errors.first('customRadio4'+index1) }}
                                        </div>
                                        <label :for="'customRadio4'+index1" class="custom-control-label">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Large modal -->
        <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade" id="bs-example-modal-lg"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">Record Multiple Records of Moisture</h4>
                        <button aria-hidden="true" class="close" data-dismiss="modal" type="button">×</button>
                    </div>
                    <div class="modal-body">
                        <button :disabled="sample_size===5" @click="addSample" class="btn btn-primary" type="button" v-if="status !== 'Attested'">Add
                            Sample
                        </button>
                        <div class="table-responsive" v-if="status === 'Attested'">
                            <table class="table table-sm table-bordered table-centered mb-0 mt-3">
                                <thead>
                                <tr>
                                    <th>Reading</th>
                                    <template v-for="(d, i) in readings">
                                        <th>Sample #{{i+1}}</th>
                                    </template>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-bind:key="index" v-for="(i, index) in no_of_readings">
                                    <td>{{i}}</td>
                                    <template v-for="j in sample_size">
                                        <td>{{readings[j-1][i-1]}}</td>
                                    </template>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <template v-for="i in sample_size">
                                        <td>{{sample_total[i-1]}}</td>
                                    </template>
                                </tr>
                                <tr>
                                    <td>Average</td>
                                    <template v-for="i in sample_size">
                                        <td>{{sample_average[i-1]}}</td>
                                    </template>
                                </tr>
                                <tr>
                                    <td>Grand Total</td>
                                    <td :colspan="sample_size">{{grand_total}}</td>
                                </tr>
                                <tr>
                                    <td>Grand Average</td>
                                    <td :colspan="sample_size" class="font-weight-bold">{{grand_average}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" v-else>
                            <table class="table table-sm table-bordered table-centered mb-0 mt-3">
                                <thead>
                                <tr>
                                    <th>Reading</th>
                                    <template v-for="(d, i) in readings">
                                        <th>Sample #{{i+1}}
                                            <button :disabled="sample_size === 1" @click="removeSample(i)"
                                                    class="text-danger btn p-0 btn-link float-right"
                                                    href="javascript:void(0)"
                                                    title="Remove this sample">
                                                <i class="mdi mdi-close-box"></i>
                                            </button>
                                        </th>
                                    </template>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-bind:key="index" v-for="(i, index) in no_of_readings">
                                    <td>{{i}}</td>
                                    <template v-for="j in sample_size">
                                        <td class="p-0">
                                            <input class="form-control border-0" type="number" v-model="readings[j-1][i-1]">
                                        </td>
                                    </template>
                                    <td>
                                        <div class="button-list">
                                            <button @click="addReadings" class="btn btn-sm btn-success"
                                                    type="button" v-if="index+1===no_of_readings && no_of_readings"><i
                                                    class="mdi mdi-plus-thick"></i></button>
                                            <button @click="removeReadings" class="btn btn-sm btn-danger" type="button"
                                                    v-if="no_of_readings>1"><i class="mdi mdi-window-close"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <template v-for="i in sample_size+1">
                                        <td class="p-0">
                                            <input class="form-control border-0" type="number" v-model="sample_total[i-1]">
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <td>Average</td>
                                    <template v-for="i in sample_size+1">
                                        <td class="p-0">
                                            <input class="form-control border-0" type="number"
                                                   v-model="sample_average[i-1]">
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <td>Grand Total</td>
                                    <td :colspan="sample_size+1" class="p-0">
                                        <input class="form-control border-0" type="number" v-model="grand_total">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Grand Average</td>
                                    <td :colspan="sample_size+1" class="p-0">
                                        <input class="font-weight-bold form-control border-0" type="number"
                                               v-model="grand_average">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer border-0" v-if="status !== 'Attested'">
                        <button class="btn btn-light" data-dismiss="modal" type="button">Close</button>
                        <button @click="save" class="btn btn-primary" data-dismiss="modal" type="button">Save changes</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import EventBus from "@/event-bus";
    import _ from "lodash";

    export default {
        name: "InspectionStep",
        computed: {
            ...mapGetters(["currentUser"]),
        },
        props: ['first_stepid', 'current_step', 'status', 'mukam'],
        watch: {
            "readings": function (nv, ov) {
                if (nv) {
                    this.calculateAverage();
                }
            }
        },
        data() {
            return {
                qualities: [],
                q_attrs: [],
                attesting: false,
                tare_weight: {
                    name: 'Tare Weight (kg)',
                    type: 'String',
                    value: 0
                },
                net_weight: {
                    name: 'Net Weight (kg)',
                    type: 'String',
                    value: 0
                },
                mr_no: {
                    name: 'MR Number',
                    type: 'String',
                    value: null
                },
                no_of_readings: 1,
                sample_size: 1,
                max_size: 5,
                readings: [[0]],
                sample_total: [0],
                sample_average: [0],
                grand_total: 0,
                grand_average: 0,
                grand_average_bkp: 0,
                attribute_name_for_save: null,
                reference: null,
                mukam_area: null,
                moisture_limit: null,
                benchMarkFlag: false,
            }
        },
        mounted() {
            if (this.status === 'Attested') {
                this.getSignedInspections()
            } else {
                this.mukam_area = this.mukam ? this.mukam.substring(0, this.mukam.indexOf('(')) : null;
                let mukam_area_code = this.mukam ? this.mukam.substring(this.mukam.indexOf('(') + 1, this.mukam.indexOf(')')) : null;
                this.getQualities();
                if (mukam_area_code) {
                    this.getMukamMoisture(mukam_area_code);
                }
                this.net_weight.value = this.$parent.gross_weight;
            }
        },
        methods: {
            async getQualities() {
                try {
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/process/${this.$parent.pid}/process_step/${this.first_stepid}/attributes`, {
                        params: {
                            process_type: 'Consignment',
                            page: 0,
                            per_page: 100
                        }
                    });
                    let q = data.attributes.filter(a => /^([Quality 0-9]{8,})$/.test(a.name));
                    let r = data.attributes.filter(a => /^([Quantity 0-9]{9,})$/.test(a.name));
                    if (q.length > 0 && r.length > 0 && q.length === r.length) {
                        this.qualities = [];
                        this.q_attrs = [];
                        q.forEach((val, index) => {
                            let attributes = JSON.parse(JSON.stringify(this.$parent.attributes));
                            let found_quality = attributes.find(a => a.name === 'Jute Quality');
                            let found_no_of_bales = attributes.find(a => a.name === 'No. of Bales');
                            if (found_quality) {
                                found_quality.fact.value = val.fact.value.toString();
                            }
                            if (found_no_of_bales) {
                                found_no_of_bales.fact.value = r[index].fact.value.toString();
                            }
                            if (this.$parent.clicked_step_index === 4) {
                                let found_tare_weight = this.$parent.attributes_bkp.find(a_bkp => this.tare_weight.name === a_bkp.name);
                                let found_net_weight = this.$parent.attributes_bkp.find(a_bkp => this.net_weight.name === a_bkp.name);
                                if (found_tare_weight) {
                                    this.tare_weight.attribute = found_tare_weight.attribute;
                                    this.tare_weight.value = found_tare_weight.fact.value;
                                }
                                if (found_net_weight) {
                                    this.net_weight.attribute = found_net_weight.attribute;
                                    this.net_weight.value = found_net_weight.fact.value;
                                }
                            }
                            attributes = attributes.map(a => {
                                let found = this.$parent.attributes_bkp.find(a_bkp => `${a.name}(${index + 1})` === a_bkp.name);
                                if (found && found.attribute) {
                                    a.attribute = found.attribute;
                                    a.fact.value = found.fact.value;
                                }
                                return {
                                    ...a,
                                    name: `${a.name}(${index + 1})`
                                }
                            });
                            this.q_attrs.push(attributes);
                            this.qualities.push({
                                quality: val,
                                quantity: r[index]
                            })
                        });
                    }
                } catch (e) {
                    console.error(e)
                }
            },
            async updateInspection() {
                let attributes = [];
                this.removeLoader = false;
                this.q_attrs.forEach(qa => {
                    attributes = attributes.concat(qa);
                });
                let update_attributes = attributes.filter(a => a.fact.value && a.attribute)
                        .map(ma => (
                                {
                                    attribute: ma.attribute,
                                    name: ma.name,
                                    type: this.$parent.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                    value: ma.fact.value,
                                }
                        ));
                let add_attributes = attributes.filter(a => a.fact.value && !a.attribute)
                        .map(ma => (
                                {
                                    name: ma.name,
                                    type: this.$parent.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                    value: ma.fact.value,
                                }
                        ));
                if (this.$parent.clicked_step_index === 4) {
                    this.tare_weight.value = this.tare_weight.value.toString();
                    this.net_weight.value = this.net_weight.value.toString();
                    this.mr_no.value = this.mr_no.value.toString();
                    if (this.tare_weight.attribute) {
                        update_attributes.push(this.tare_weight);
                    } else {
                        add_attributes.push(this.tare_weight)
                    }
                    if (this.net_weight.attribute) {
                        update_attributes.push(this.net_weight);
                    } else {
                        add_attributes.push(this.net_weight)
                    }
                    if (this.mr_no.attribute) {
                        update_attributes.push(this.mr_no);
                    } else {
                        add_attributes.push(this.mr_no)
                    }
                }
                try {
                    await this.$http.patch(`mill/${this.currentUser.mill_id}/processes/${this.$parent.pid}/steps/${this.$parent.step_to_update}`, {
                        with_did: this.currentUser.did,
                        add_attributes: add_attributes,
                        update_attributes: update_attributes,
                        process_type: 'Consignment'
                    });
                    if (!this.attesting) {
                        await this.$parent.getAttributes(this.$parent.step_to_update);
                        await this.getQualities();
                        this.$toastr.s('Attribute(s) successfully updated', 'Success');
                    }
                } catch (e) {
                    this.$toastr.e('Process step updating failed', 'Failed');
                } finally {
                    this.$parent.removeLoader = true;
                }
            },
            async getSignedInspections() {
                try {
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/process/${this.$parent.pid}/process_step/${this.first_stepid}/attributes`, {
                        params: {
                            process_type: 'Consignment',
                            page: 0,
                            per_page: 100
                        }
                    });
                    let q = data.attributes.filter(a => /^([Quality 0-9]{8,})$/.test(a.name));
                    if (this.$parent.clicked_step_index === 4) {
                        let found_tare_weight = this.$parent.attributes_bkp.find(a_bkp => this.tare_weight.name === a_bkp.name);
                        let found_net_weight = this.$parent.attributes_bkp.find(a_bkp => this.net_weight.name === a_bkp.name);
                        let found_mr_no = this.$parent.attributes_bkp.find(a_bkp => this.mr_no.name === a_bkp.name);
                        if (found_tare_weight) {
                            this.tare_weight.value = found_tare_weight.fact.value;
                        }
                        if (found_net_weight) {
                            this.net_weight.value = found_net_weight.fact.value;
                        }
                        if (found_mr_no) {
                            this.mr_no.value = found_mr_no.fact.value;
                        }
                    }
                    if (q.length > 0) {
                        this.qualities = [];
                        this.q_attrs = [];
                        q.forEach((val, index) => {
                            let attributes = this.$parent.attributes_bkp.filter(a_bkp => a_bkp.name.indexOf(`(${index + 1})`) > 0);
                            this.q_attrs.push(attributes);
                            this.qualities.push({
                                quality: val
                            })
                        })
                    }
                } catch (e) {
                    console.error(e)
                }
            },
            async attestInspection() {
                try {
                    EventBus.$emit('openLoader');
                    this.attesting = true;
                    await this.updateInspection();
                    await this.$http.post(`mill/${this.currentUser.mill_id}/processes/${this.$parent.pid}/steps/${this.$parent.step_to_update}/attest`, {
                        process_definition_step_index: this.current_step,
                        process_type: 'Consignment'
                    });
                    this.$toastr.s('Successfully signed', 'Success');
                } catch (e) {
                    this.$toastr.e('Attestation failed', 'Failed');
                } finally {
                    this.attesting = false;
                    EventBus.$emit('closeLoader');
                }
            },
            change(name, value, q_attr, index) {
                if (name.indexOf('Claim') === 0 && value == 100) {
                    let found = q_attr.find(a => a.name === `Jute Quality(${index + 1})`);
                    if (found) {
                        let fact_value = Number(found.fact.value.substring(2)) > 1 ? Number(found.fact.value.substring(2)) - 1 : Number(found.fact.value.substring(2));
                        found.fact.value = `TD${fact_value}`;
                        q_attr.find(a => a.name.indexOf('Quality') === 0).fact.value = 'Claim';
                    }
                }
                if (name.indexOf('Quality') === 0 && value == 'Pass') {
                    let found = q_attr.find(a => a.name.indexOf('Claim') === 0);
                    if (found) {
                        found.fact.value = 0
                    }
                }
            },
            changeNetWeight() {
                this.net_weight.value = (Number(this.$parent.gross_weight) - Number(this.tare_weight.value)).toString()
            },
            async getReadings(attribute_name, q_attr) {
                this.attribute_name_for_save = attribute_name;
                this.reference = q_attr;
                this.reset();
                if (this.$parent.pid && this.current_step) {
                    try {
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes/${this.$parent.pid}/steps/${this.current_step}/attribute_readings`, {
                            params: {
                                name: attribute_name,
                                process_type: 'Consignment'
                            }
                        });
                        if (data.readings.length > 0) {
                            let samples = _.groupBy(data.readings, 'sample');
                            this.readings = [];
                            this.sample_size = 0;
                            for (let key in samples) {
                                this.sample_size++;
                                this.no_of_readings = samples[key].length;
                                let readings = [];
                                samples[key].forEach(reading => {
                                    readings.push(reading.reading)
                                });
                                this.readings.push(readings);
                            }
                        }
                    } catch (e) {

                    }
                }
            },
            addSample() {
                if (this.sample_size < 5) {
                    this.sample_size++;
                    let reading_arr = [];
                    for (let i = 0; i < this.no_of_readings; i++) {
                        reading_arr.push(0)
                    }
                    this.readings.push(reading_arr);
                    this.sample_total.push(0);
                    this.sample_average.push(0);
                }
            },
            addReadings() {
                if (this.no_of_readings < 10) {
                    for (let i = 0; i < this.readings.length; i++) {
                        this.readings[i].push(0)
                    }
                    this.no_of_readings++;
                }
            },
            removeReadings() {
                if (this.no_of_readings > 1) {
                    for (let i = 0; i < this.readings.length; i++) {
                        this.readings[i].pop();
                    }
                    this.no_of_readings--;
                }
            },
            removeSample(i) {
                if (this.sample_size > 1) {
                    this.readings.splice(i, 1);
                    this.sample_total.splice(i, 1);
                    this.sample_average.splice(i, 1);
                    this.sample_size--;
                }
            },
            calculateAverage() {
                this.sample_total = [];
                this.sample_average = [];
                this.grand_total = 0;
                this.grand_average = 0;
                let average = 0;
                this.readings.forEach((r, i) => {
                    let sample_total = 0;
                    let sets = this.readings[i];
                    sets.forEach((set, j) => {
                        sample_total += Number(this.readings[i][j]);
                    });
                    this.sample_total.push(sample_total);
                    this.sample_average.push(Number(sample_total) / this.no_of_readings);
                    this.grand_total += sample_total;
                    average += (Number(sample_total) / this.no_of_readings);
                });
                this.grand_average = (Number(average) / Number(this.sample_size)).toFixed(2);
            },
            async save() {
                let request_body = [];
                this.readings.forEach((r, i) => {
                    this.readings[i].forEach((rr, j) => {
                        let element = {
                            attribute_name: this.attribute_name_for_save,
                            sample: i + 1,
                            reading_number: j + 1,
                            reading: rr.toString()
                        }
                        request_body.push(element);
                    })
                });
                try {
                    await this.$http.delete(`mill/${this.currentUser.mill_id}/processes/${this.$parent.pid}/steps/${this.current_step}/attribute_readings`, {
                        params: {
                            name: this.attribute_name_for_save,
                            process_type: 'Consignment'
                        }
                    });
                    await this.$http.post(`mill/${this.currentUser.mill_id}/processes/${this.$parent.pid}/steps/${this.current_step}/attribute_readings`, {
                        readings: request_body,
                        process_type: 'Consignment'
                    });
                    this.grand_average_bkp = this.grand_average;
                    this.reference.fact.value = this.grand_average_bkp.toString();
                    this.reference.readonly = true;
                    if (Number(this.grand_average_bkp) > Number(this.moisture_limit) || Number(this.grand_average_bkp) < Number(this.moisture_limit)) {
                        this.benchMarkFlag = true;
                    }else {
                        this.benchMarkFlag = false;
                    }
                    this.reset();
                } catch (e) {

                }
            },
            reset() {
                this.no_of_readings = 1;
                this.sample_size = 1;
                this.max_size = 5;
                this.readings = [[0]];
                this.sample_total = [0];
                this.sample_average = [0];
                this.grand_total = 0;
                this.grand_average = 0
            },
            async getMukamMoisture(area_code) {
                try {
                    let {data} = await this.$http.get(`/mukam_area_by_code/${area_code}`);
                    this.moisture_limit = data ? data.moist : null
                } catch (e) {

                }
            },
            checkBenchmark(moisture) {
                this.benchMarkFlag = false;
                if (Number(moisture) > Number(this.moisture_limit) || Number(moisture) < Number(this.moisture_limit)) {
                    this.benchMarkFlag = true;
                }else {
                    this.benchMarkFlag = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>