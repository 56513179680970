<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body" v-if="process_steps.length>0">

          <!-- Checkout Steps -->
          <div id="progressbarwizard" v-if="active_steps.length>0">
            <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
              <li class="nav-item" v-bind:key="index" v-for="(process_step, index) in active_steps">
                <a :class="{'active': attestor_step == process_step.order}" @click="setStepIndex(process_step.order, process_step.process_definition_step, process_step.process_step)" aria-expanded="false" class="nav-link rounded-0" data-toggle="tab" v-bind:href="'#tab'+process_step.process_definition_step">
                  <i :class="`mdi mdi-numeric-${index+1}-box-multiple font-18`"></i>
                  <span class="d-none d-lg-block">{{process_step.name}}</span>
                </a>
              </li>
            </ul>

            <!-- Steps Information -->
            <div class="tab-content">

              <div v-for="process_step in active_steps" :key="process_step.process_step" :class="{'active show': clicked_step_index == process_step.order}" :id="'#tab'+process_step.process_definition_step" class="tab-pane">
                <a class="p-0 float-right font-weight-semibold mb-3" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/provenance/'+pid">
                  <i class="mdi mdi-vector-link"></i>&nbsp;View on Blockchain</a>
                  <h4 class="mt-2 mb-2">{{process_step.name}}&nbsp;&nbsp;</h4>
                <p class="text-muted mb-4" v-if="process_step.description">{{process_step.description}}</p>
                <p class="text-muted mb-4" v-else></p>
                <form v-if="attributes.length>0 && clicked_step_index==process_step.order">
                  <template v-if="process_step.status !== 'Attested'">
                    <div class="row">
                        <div class="col-12" v-if="process_step.order === 2 || process_step.order === 3 ||  process_step.order === 4 ">
                            <inspection-step :mukam="mukam" ref="reference" :current_step="process_step.process_step" :first_stepid="process_steps[0].process_step" :status="process_step.status"></inspection-step>
                        </div>
                      <div class="col-12" v-else>
                          <div v-if="truck_no && process_step.order>0" class="form-group row">
                              <label class="col-md-3 col-form-label">Truck No</label>
                              <div class="col-md-9">
                                  <input readonly :value="truck_no" class="form-control">
                              </div>
                          </div>
                          <div v-if="gross_weight && process_step.order === 4" class="form-group row">
                              <label class="col-md-3 col-form-label">Gross Weight (kg)</label>
                              <div class="col-md-9">
                                  <input readonly :value="gross_weight" class="form-control">
                              </div>
                          </div>
                        <div class="form-group row mb-3" v-bind:key="index1" v-for="(a, index1) in attributes">
                          <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{a.name}}</label>
                            <div class="col-md-9" v-if="a.name === 'Broker Name'">
                                <multiselect
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :hide-selected="true"
                                        :internal-search="false"
                                        :options="vendors"
                                        :searchable="true"
                                        :selectLabel="''"
                                        :show-no-results="false"
                                        @search-change="getVendors"
                                        @input="setVendor(attributes[index1], a.fact.value)"
                                        class="form-control multiselect multiselect-sm"
                                        id="customer"
                                        label="Search Counterparty"
                                        name="customer"
                                        open-direction="bottom"
                                        placeholder="Search Broker"
                                        v-model="a.fact.value">
                                    <template slot="singleLabel" slot-scope="props">
                                        {{a.fact.value}}
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <h5>{{ props.option.vendor_name }}</h5>
                                            <div>{{ props.option.vendor_type }}, {{ props.option.vendor_no }}</div>
                                            <div>{{ props.option.address }}</div>
                                        </div>
                                    </template>
                                    <span slot="noResult">Counterparty not found</span>
                                </multiselect>
                            </div>
                            <div class="col-md-9" v-else-if="a.name === 'Mukam Name'">
                                <multiselect
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :hide-selected="true"
                                        :internal-search="false"
                                        :options="mukams"
                                        :searchable="true"
                                        :selectLabel="''"
                                        :show-no-results="false"
                                        @search-change="getMukams"
                                        @input="setMukam(attributes[index1], a.fact.value)"
                                        class="form-control multiselect multiselect-sm"
                                        id="mukam"
                                        label="Search Counterparty"
                                        name="customer"
                                        open-direction="bottom"
                                        placeholder="Search Mukam"
                                        v-model="a.fact.value">
                                    <template slot="singleLabel" slot-scope="props">
                                        {{a.fact.value}}
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <h5>{{ props.option.mukam_name }}</h5>
                                            <div>{{ props.option.mukam_code }}, {{ props.option.area_code }}</div>
                                        </div>
                                    </template>
                                    <span slot="noResult">Counterparty not found</span>
                                </multiselect>
                            </div>
                            <div class="col-md-9" v-else>
                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" :readonly="a.readonly" class="form-control" type="text" v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" :readonly="a.readonly" @input="calculate(a)" class="form-control" type="number" v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <select :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :disabled="a.readonly" :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" class="form-control custom-select" v-if="a.values && a.values.length >0 && process_step.status !== 'Attested'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                    <option :value="null" selected>Select {{a.name}}</option>
                                    <option :value="val" v-for="val in a.values" :key="val">
                                        {{val}}
                                    </option>
                                </select>
                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" :readonly="a.readonly" class="form-control" type="date" v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" :readonly="a.readonly" @input="calculate(a)" class="form-control" type="number" v-if="(!a.values || a.values.length === 0) && a.fact.type === 'Integer'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                <div class="invalid-feedback" v-if="submitted && errors.has('attribute_value1'+index1)">
                                    {{errors.first('attribute_value1'+index1) }}
                                </div>
                                <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input :class="{'is-invalid': submitted && errors.has('customRadio3'+index1)}" value="true" :id="'customRadio3'+index1" :readonly="a.readonly" class="custom-control-input" name="customRadio1" type="radio" v-validate="{ required: a.required }">
                                        <div class="invalid-feedback" v-if="submitted && errors.has('customRadio3'+index1)">
                                            {{
                                            errors.first('customRadio3'+index1) }}
                                        </div>
                                        <label :for="'customRadio3'+index1" class="custom-control-label">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input :class="{'is-invalid': submitted && errors.has('customRadio4'+index1)}" value="false" :id="'customRadio4'+index1" :readonly="a.readonly" class="custom-control-input" name="customRadio1" type="radio" v-validate="{ required: a.required }">
                                        <div class="invalid-feedback" v-if="submitted && errors.has('customRadio4'+index1)">
                                            {{
                                            errors.first('customRadio4'+index1) }}
                                        </div>
                                        <label :for="'customRadio4'+index1" class="custom-control-label">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                          <div v-if="process_step.order === 0" class="form-group row" v-for="(val, q_index) in qualities">
                              <label class="col-md-3 col-form-label">Jute Quality & Quantity</label>
                              <div class="col-md-4">
                                  <select v-validate="{ required: q_index==0 }" :class="{'is-invalid': submitted && errors.has('quality_'+q_index)}" data-vv-as="Quality" :id="'quality_'+q_index" :name="'quality_'+q_index" class="form-control custom-select" v-model="val.quality.fact.value">
                                      <option :value="null" selected>Select Quality</option>
                                      <option :value="val" v-for="val in jute_qualities" :key="val">
                                          {{val}}
                                      </option>
                                  </select>
                                  <div class="invalid-feedback" v-if="submitted && errors.has('quality_'+q_index)">
                                      {{errors.first('quality_'+q_index) }}
                                  </div>
                              </div>
                              <div class="col-md-3">
                                  <input v-validate="{ required: q_index==0 }" :class="{'is-invalid': submitted && errors.has('quantity_'+q_index)}" data-vv-as="Quantity" :required="q_index==0" @input="quantityChange()" type="number" :id="'quantity_'+q_index" :name="'quantity_'+q_index" placeholder="Input Quantity" v-model="val.quantity.fact.value" class="m-auto form-control">
                                  <div class="invalid-feedback" v-if="submitted && errors.has('quantity_'+q_index)">
                                      {{errors.first('quantity_'+q_index) }}
                                  </div>
                              </div>
                              <div class="col-md-1">
                                  <button @click="addQuality(q_index)" type="button" class="btn btn-block btn-outline-primary"><i class="mdi mdi-plus-circle"></i></button>
                              </div>
                              <div class="col-md-1">
                                  <button :disabled="q_index===0" @click="removeQuality(q_index, val)" type="button" class="btn btn-block btn-outline-danger"><i class="mdi mdi-minus-circle"></i></button>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-sm-6">
                        <router-link :to="{name: 'consignments'}" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <i class="mdi mdi-arrow-left"></i> Back to Consignments
                        </router-link>
                      </div>
                      <div class="col-sm-6" v-if="attestor_step === process_step.order">
                        <div class="text-sm-right">
                          <a :class="{'not-active': updating}" @click="![2, 3, 4].includes(process_step.order) ? updateStep(): updateInspection()" class="btn btn-primary" href="javascript:void(0)">
                            <i class="mdi mdi-content-save mr-1"></i> Save as Draft </a>
                          <a :class="{'not-active': attesting}" @click="![2, 3, 4].includes(process_step.order) ? attest() : attestInspection()" class="ml-1 btn btn-success" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-right-bold-circle-outline mr-1"></i>
                            Confirm </a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                        <div class="col-12" v-if="process_step.order === 2 || process_step.order === 3 ||  process_step.order === 4 ">
                            <inspection-step :mukam="mukam" ref="reference" :current_step="process_step.process_step" :first_stepid="process_steps[0].process_step" :status="process_step.status"></inspection-step>
                        </div>
                      <div class="col-12" v-else>
                          <div v-if="truck_no && process_step.order>0" class="form-group row">
                              <label class="col-md-3 col-form-label">Truck No</label>
                              <div class="col-md-9">
                                  <input readonly :value="truck_no" class="form-control">
                              </div>
                          </div>
                          <div v-if="gross_weight && process_step.order === 4" class="form-group row">
                              <label class="col-md-3 col-form-label">Gross Weight (kg)</label>
                              <div class="col-md-9">
                                  <input readonly :value="gross_weight" class="form-control">
                              </div>
                          </div>
                        <div class="form-group row mb-3" v-for="(a, index1) in attributes" :key="a.name">
                          <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{a.name}}</label>
                          <div class="col-md-9">
                            <input readonly class="form-control" type="text" v-model="a.fact.value">
                          </div>
                        </div>
                          <div v-if="process_step.order===0" class="form-group row" v-for="(val, q_index) in qualities">
                              <label class="col-md-3 col-form-label">Jute Quality & Quantity</label>
                              <div class="col-md-4">
                                  <input readonly class="form-control" type="text" v-model="val.quality.fact.value">
                              </div>
                              <div class="col-md-5">
                                  <input readonly class="form-control" type="text" v-model="val.quantity.fact.value">
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-sm-6">
                        <router-link :to="{name: 'consignments'}" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <i class="mdi mdi-arrow-left"></i> Back to Consignments
                        </router-link>
                      </div> <!-- end col -->
                      <div class="col-sm-6" v-if="process_step.status === 'Attested'">
                        <p class="text-sm-right text-muted font-weight-semibold">Signed on&nbsp;<small class="text-muted">{{process_step.attested_at | shorttimestamp}}</small>
                        </p>
                      </div>
                    </div> <!-- end row -->
                  </template>
                </form>
                <div class="d-flex justify-content-center mb-3" v-else-if="!removeLoader">
                  <div class="spinner-border avatar-md text-primary" role="status"></div>
                </div>
                  <div class="m-5" v-else-if="removeLoader && !attesting">
                      <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
                  </div>
              </div>
            </div>

          </div>
            <div class="col-12 text-center alert alert-warning alert-dismissible fade show"
                 role="alert"
                 v-else>
                <strong>Warning - </strong> waiting for signer(s) of the previous step(s) to add
                information and signing !
            </div>

        </div> <!-- end card-body-->
      </div> <!-- end card-->
    </div> <!-- end col -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../../../../event-bus";
import InspectionStep from "./InspectionStep";
import Multiselect from 'vue-multiselect';

export default {
  name: "Step1",
    components: {InspectionStep, Multiselect},
    props: ['consignmentid'],
  computed: {
    ...mapGetters(["currentUser"]),
    active_steps: function () {
      return this.process_steps.filter(process_step => process_step.order <= this.current_step && (this.attestor_step == this.current_step || process_step.order < this.current_step));
    }
  },
  data() {
    return {
      pid: null,
      submitted: false,
      current_step: 0,
      isCurrentStep: true,
      clicked_step_index: 0,
      process_steps: [],
      attestor_step: -1,
      definition_attributes: [],
      attributes: [],
      attestors: [],
      attestor_process_definition_step: null,
      step_to_update: null,
      user: {
        mill_id: null
      },
      updating: false,
      attesting: false,
      truck_no: null,
      gross_weight: null,
        removeLoader: false,
        jute_qualities: ['TD1', 'TD2', 'TD3', 'TD4', 'TD5', 'TD6', 'TD7', 'TD8', 'TD9', 'TD10'],
        qualities: [
            {
                quality: {
                    name: 'Quality 1',
                    fact: {
                        type: 'Text',
                        value: null
                    }
                },
                quantity: {
                    name: 'Quantity 1',
                    fact: {
                        type: 'Integer',
                        value: null
                    }
                }
            }
        ],
        attributes_bkp: [],
        process_status: null,
        vendors: [],
        mukams: [],
        mukam: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getProcess();
      await this.getProcessSteps();
      if (this.attestor_step == this.current_step) {
        await this.getDefinitionAttributes(this.attestor_process_definition_step);
        this.isCurrentStep = true;
        await this.getAttributes(this.step_to_update);

      } else if(this.active_steps.length >0){
        this.isCurrentStep = false;
        await this.getAttributes(this.active_steps[this.active_steps.length-1].process_step);

      }
    },
    async getProcess() {
      try {
          let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
              params: {
                  process_type: 'Consignment',
                  page: 0,
                  per_page: 100,
                  search: this.consignmentid,
                  fetch: {
                      attributes: [{
                          attribute_name: "Truck No.",
                          attribute_step: 0
                      },{
                          attribute_name: "Gross Weight (kg)",
                          attribute_step: 1
                      },{
                          attribute_name: "Mukam Name",
                          attribute_step: 0
                      }]
                  }
              }
          });
          this.current_step = data.processes[0].current_step;
          this.truck_no = data.processes[0].attributes.length>0?data.processes[0].attributes[0][2].fact.value:null;
          this.gross_weight = data.processes[0].attributes.length>1?data.processes[0].attributes[1][2].fact.value:null;
          this.mukam = data.processes[0].attributes.length>2?data.processes[0].attributes[2][2].fact.value:null;
          this.process_status = data.processes[0].status;
        this.pid = data.processes[0].process;
      } catch (e) {
        console.error(e);
      }
    },
    async getProcessSteps() {
      try {
        let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps`, {
          params: {
            page: 0,
            per_page: 100,
            process_type: 'Consignment'
          }
        });
        this.process_steps = data.process_steps;
        if(this.process_status !== 'Completed'){
            this.process_steps.forEach(step => {
                let found = step.attestors.find(sa => this.currentUser.did === sa.did && (step.status === 'Created' || step.status === 'Attested'));
                if (found && found.did) {
                    this.attestor_process_definition_step = step.process_definition_step;
                    this.step_to_update = step.process_step;
                    this.attestor_step = step.order;
                    this.clicked_step_index = step.order;
                }
            });
        }else {
            this.attestor_step = this.clicked_step_index = this.active_steps.length-1;
        }
      } catch (e) {
        console.error(e);
        this.$toastr.e('Failed to get steps', 'Failed');
      }
    },
    async getDefinitionAttributes(definition_step_id) {
      if (definition_step_id) {
        try {
          let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_step/${definition_step_id}/attributes`, {
            params: {
              page: 0,
              per_page: 100,
                process_type: 'Consignment'
            }
          });
          this.definition_attributes = data.process_definition_step_attributes.map(a => {
            let attribute = {
              ...a,
              fact: {
                type: a.fact_type,
                value: null
              },
              readonly: false,
              values: a.options && typeof a.options === 'string' ? a.options.split(';') : []
            };
            switch (a.name) {
              case 'Jute Mill Name':
                attribute.fact.value = 'Shaktigarh Textiles Industries Ltd.';
                attribute.readonly = true;
                break;
              case 'Unit Name':
                attribute.fact.value = 'Hastings Jute Mill';
                attribute.readonly = true;
                break;
              case 'Location':
                attribute.fact.value = 'Rishra, Hooghly';
                attribute.readonly = true;
                break;

            }

            return attribute;
          });
        } catch (e) {
          console.error(e);
          this.$toastr.e('Failed to get attributes', 'Failed');
        }
      }
    },
    async getAttributes(process_step_id) {
      if (process_step_id) {
        try {
            this.removeLoader = false;
          let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/process/${this.pid}/process_step/${process_step_id}/attributes`, {
            params: {
              page: 0,
              per_page: 100,
                process_type: 'Consignment',
                sort_by: 'created',
                order: 'ASC'
            }
          });
          this.attributes_bkp = data.attributes;
          if(this.clicked_step_index === 0) {
              let q = data.attributes.filter(a => /^([Quality 0-9]{8,})$/.test(a.name));
              let r = data.attributes.filter(a => /^([Quantity 0-9]{9,})$/.test(a.name));
              if (q.length > 0 && r.length > 0 && q.length === r.length) {
                  this.qualities = [];
                  q.forEach((val, index) => {
                      this.qualities.push({
                          quality: val,
                          quantity: r[index]
                      })
                  })
              }
          }
          if (this.isCurrentStep) {
            this.definition_attributes.forEach(da => {
              let found = data.attributes.find(a => da.name === a.name);
              if (found && found.attribute) {
                da.attribute = found.attribute;
                da.fact.value = found.fact.value;
              }
            });
            this.attributes = this.definition_attributes;
          }
          else {
            let attributes = data.attributes.filter(a=> !/^([Quality 0-9]{8,})$/.test(a.name));
            this.attributes = attributes.filter(a=> !/^([Quantity 0-9]{9,})$/.test(a.name));
          }
        } catch (e) {
          console.error(e);
          this.$toastr.e('Failed to get attributes', 'Failed');
        }finally {
            this.removeLoader = true;
        }
      }

    },
    async setStepIndex(i, process_definition_step, process_step) {
      this.attributes = [];
      this.clicked_step_index = i;
      if (this.current_step == i) {
        await this.getDefinitionAttributes(process_definition_step);
        this.isCurrentStep = true;
        await this.getAttributes(process_step);
      } else {
        this.isCurrentStep = false;
        await this.getAttributes(process_step);
      }
    },
    getDatatypeForUpdate(type, val) {
      switch (type) {
        case "Text":
        case "Float":
          return 'String';
        case "Integer":
          return 'U128';
        case "Yes/No":
          return "Bool";
        default:
          return type;
      }
    },
    async updateStep() {
      this.submitted = false;
      let attributes = this.attributes;
      this.attributes = [];
      if(!this.attesting) {
          this.removeLoader = false;
      }
      if(this.current_step === 0) {
          this.qualities.forEach(q => {
              attributes.push(q.quality);
              attributes.push(q.quantity);
          })
      }
      let update_attributes = attributes.filter(a => a.fact.value && a.attribute)
        .map(ma => (
          {
            attribute: ma.attribute,
            name: ma.name,
            type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
            value: ma.fact.value,
          }
        ));

      let add_attributes = attributes.filter(a => a.fact.value && !a.attribute)
        .map(ma => (
          {
            name: ma.name,
            type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
            value: ma.fact.value,
          }
        ));
      try {
        this.updating = true;
        await this.$http.patch(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}`, {
          with_did: this.currentUser.did,
          add_attributes: add_attributes,
          update_attributes: update_attributes,
            process_type: 'Consignment'
        });
        if(!this.attesting) {
            await this.getDefinitionAttributes(this.attestor_process_definition_step);
            this.isCurrentStep = true;
            await this.getAttributes(this.step_to_update);
            this.$toastr.s('Attribute(s) successfully updated', 'Success');
        }
      } catch (e) {
        this.$toastr.e('Process step updating failed', 'Failed');
      } finally {
        this.updating = false;
        this.removeLoader = true;
      }
    },
    async attest() {
      this.submitted = true;
      let valid = await this.$validator.validate();
      if (valid) {
        try {
          EventBus.$emit('openLoader');
          this.attesting = true;
          await this.updateStep();
          await this.$http.post(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}/attest`, {
              process_definition_step_index: this.step_to_update,
              process_type: 'Consignment'
          });
          await this.getProcess();
          await this.getProcessSteps();
          await this.getDefinitionAttributes(this.attestor_process_definition_step);
          this.isCurrentStep = true;
          await this.getAttributes(this.step_to_update);
          this.$toastr.s('Successfully signed', 'Success');
        } catch (e) {
          this.$toastr.e('Attestation failed', 'Failed');
        } finally {
          EventBus.$emit('closeLoader');
          this.attesting = false;
        }
      }
    },
    calculate(attribute) {
      if (attribute.name === 'Tare Weight (kg)') {
        this.attributes.find(a => a.name === 'Net Weight (kg)').fact.value = (Number(this.gross_weight) - Number(attribute.fact.value)).toString();
      }
    },
    addQuality(index){
        index++;
        this.qualities.push(
                {
                    quality: {
                        name: `Quality ${index+1}`,
                        fact: {
                            type: 'Text',
                            value: null
                        }
                    },
                    quantity: {
                        name: `Quantity ${index+1}`,
                        fact: {
                            type: 'Integer',
                            value: null
                        }
                    }
                }
        );
    },
    async removeQuality(index, val) {
        if(val.quality.attribute && val.quantity.attribute){
            try {
                EventBus.$emit('openLoader');
                let found = this.attributes.find(a => a.name === 'No. of Bales');
                found.fact.value = Number(found.fact.value) - Number(val.quantity.fact.value);
                await this.$http.patch(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}`, {
                    with_did: this.currentUser.did,
                    process_type: 'Consignment',
                    remove_attributes: [
                        {
                            attribute: val.quality.attribute,
                            name: val.quality.name,
                            type: this.getDatatypeForUpdate(val.quality.fact.type, val.quality.fact.value ? val.quality.fact.value : null),
                            value: val.quality.fact.value
                        },
                        {
                            attribute: val.quantity.attribute,
                            name: val.quantity.name,
                            type: this.getDatatypeForUpdate(val.quantity.fact.type, val.quantity.fact.value ? val.quantity.fact.value : null),
                            value: val.quantity.fact.value
                        }
                    ],
                    update_attributes: [
                        {
                            attribute: found.attribute,
                            name: found.name,
                            type: this.getDatatypeForUpdate(found.fact.type, found.fact.value ? found.fact.value : null),
                            value: found.fact.value.toString()
                        }
                    ]
                });
                this.$toastr.s('Successfully removed', 'Success');
            }catch (e) {
                this.$toastr.e('Remove attribute failed', 'Failed');
            }finally {
                EventBus.$emit('closeLoader');
            }
        }
      this.qualities.splice(index, 1);
    },
      quantityChange(){
        let no_of_bales = 0;
          this.qualities.forEach(q=> {
              no_of_bales= Number(no_of_bales) + Number(q.quantity.fact.value);
          });
          this.attributes.find(a => a.name === 'No. of Bales').fact.value = no_of_bales.toString();
      },
      async updateInspection(){
          await this.$refs.reference[0].updateInspection();
      },
      async attestInspection(){
          await this.$refs.reference[0].attestInspection();
          await this.getProcess();
          await this.getProcessSteps();
          await this.getDefinitionAttributes(this.attestor_process_definition_step);
          this.isCurrentStep = true;
          await this.getAttributes(this.step_to_update);
          await this.$refs.reference[0].getQualities();
      },
      async getVendors(searchText){
        try {
            let {data} =  await this.$http.get(`/vendors`, {
                params: {
                    search_name: searchText
                }
            });
            this.vendors = data;
        }catch (e) {

        }
      },
      setVendor(arr_ref, vendor){
          arr_ref.fact.value = `${vendor.vendor_name} (${vendor.vendor_no})`;
      },
      async getMukams(searchText){
          try {
              let {data} =  await this.$http.get(`/mukams`, {
                  params: {
                      search_name: searchText
                  }
              });
              this.mukams = data;
          }catch (e) {

          }
      },
      setMukam(arr_ref, mukam){
          arr_ref.fact.value = `${mukam.mukam_name} (${mukam.area_code})`;
          this.attributes.find(a => a.name === 'Origin (Mukam Area)').fact.value = mukam.area_name.toString();
      }
  }
}
</script>

<style scoped>
.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}
</style>